import React from "react"
import PageTemplate from "../components/pageTemplate"
import { Row, Col } from "reactstrap"
import myline from "../assets/images/myline_logo_orange_web.jpg"

let Ueber = () => (
  <PageTemplate title="myLine">
    <h2>myLINE: Gezieltes Abnehmen unter ärztlicher Anleitung</h2>
    <Row className="d-flex align-items-start">
      <Col md="6">
        <div className="mb-4">
          <p>
            Das myLINE 6-Elemente-Schlankheitsprogramm ist der medizinisch
            sinnvolle Weg zu Ihrem Wunschgewicht. Unter ärztlicher Anleitung und
            in Zusammenarbeit mit einer Diaetologin, lernen Sie, sich gesund und
            ausgewogen zu ernähren, um so Ihrem Wunschgewicht Schritt für
            Schritt näherzukommen. Das Ziel ist eine langfristige Reduktion des
            Gewichts durch eine umfassende Ernährungsumstellung, ohne zu
            hungern. Ein individuell auf Sie abgestimmter Ernährungsplan, der
            Ihre Lebens- und Essgewohnheiten berücksichtigt, unterstützt sie
            dabei. Regelmäßige BIA-Messungen, durchgeführt mit modernsten
            Geräten, geben Aufschluss über Ihre Körperzusammensetzung und
            ermöglichen eine ständige Erfolgskontrolle. Das myLINE
            6-Elmente-Schlankheitsprogramm wurde in Zusammenarbeit mit Ärzten
            des Universitätsklinikums Graz entwickelt und die Wirkungsweise in
            wissenschaftlichen Studien bestätigt.
          </p>
          <p>
            Informieren Sie sich in unserer Ordination über den nächsten
            kostenlosen Infoabend!
          </p>
          <p>
            <a
              className="btn btn-primary"
              href="https://www.myline.at"
              title="Abnehmen mit myLINE - hier bestellen"
              target="_blank"
              rel="noreferrer"
            >
              Abnehmen mit myLine - hier bestellen
            </a>
          </p>
        </div>
      </Col>
      <Col md="6">
        <img src={myline} alt="myLINE" height="180px" />
      </Col>
    </Row>
  </PageTemplate>
)

export default Ueber
